import React, { useState, useEffect } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Box, Grid, Button, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccountSetup from "./components/AccountSetup";
import GiftDeliveryDetails from "./components/GiftDeliveryDetails";
import ExclusiveAddons from "./components/ExclusiveAddons";
import PaymentInformation from "./components/PaymentInformation";
import OrderSummary from "./components/OrderSummary";
import useFormData from "./hooks/useFormData";
import useTotalPrice from "./hooks/useTotalPrice";
import { handleSubmit } from "./utils/checkoutHelpers";
import ContainerBox from "../../shared/ContainerBox";

const Checkout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const { formData, handleChange, error, setError } = useFormData();
  const totalPrice = useTotalPrice(formData);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Redirect for null questionnaire data
  const questionnaireData = JSON.parse(
    localStorage.getItem("questionnaireData")
  );
  const navigate = useNavigate();

  if (!questionnaireData) {
    navigate("/questionnaire");
  }

  return (
    <ContainerBox
      component="form"
      onSubmit={(e) =>
        handleSubmit(
          e,
          stripe,
          elements,
          formData,
          setError,
          setIsSubmitting
        )
      }
      id="payment-form"
    >
      <Grid
        container
        sx={{
          maxWidth: { md: 1200 },
          margin: "auto",
          padding: { xs: 0, sm: 0, md: "1rem" },
        }}
      >
        <Grid item xs={12} md={8}>
          <AccountSetup formData={formData} handleChange={handleChange} />
          <GiftDeliveryDetails
            formData={formData}
            handleChange={handleChange}
          />
          <ExclusiveAddons formData={formData} handleChange={handleChange} />
          <PaymentInformation />
          {error && (
            <Alert severity="error" sx={{ mt: 6 }}>
              {error}
            </Alert>
          )}
          <Box sx={{ mt: 8 }}>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!stripe || isSubmitting}
            >
              {isSubmitting ? "Processing..." : "Pay"}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <OrderSummary formData={formData} totalPrice={totalPrice} />
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default Checkout;
